import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Select, Pagination, useModal } from '@sumup/circuit-ui';
import { Error as ErrorIcon } from 'assets/icons';
import MainContent from 'components/MainContent';
import Loading from 'components/Loading';
import DefaultSection from 'components/DefaultSection';
import BlackButton from 'components/BlackButton';
import ReaderImageCard from '../ReaderImageCard';
import ConfirmationModal from '../UploadCarrierSuggestion/components/ConfirmationModal';
import { calculatePaginationItens } from '../../services/PaginationService';

import {
  getReaderAvailabilityData,
  mapCountriesAvailable,
  shouldRenderTable,
  filterByCountry,
  toggleAvailabilityBulk
} from './ReaderAvailabilityService';

const Container = styled.div`
  display: flex;
  margin-top: 1.5em;
`;

const ContentContainer = styled.div`
  flex-grow: 2;
`;

const FilterContainer = styled.span`
  display: flex;
  align-items: center;
  max-width: 450px;
  & p {
    margin-right: 0.5em;
  }
`;

const TableContainer = styled.div`
  & tr:hover {
    opacity: 0.65;
    transition: 0.35s ease-out;
  }
`;

const StyledSelect = styled(Select)`
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
  & span {
    visibility: hidden;
    margin-top: -2em;
    display: block;
  }
`;

const StyledPagination = styled(Pagination)`
  justify-content: end;

  & button,
  & button:hover {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
  & button:focus {
    box-shadow: 0 0 0 4px #ddd;
  }
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const SpaceBetweenHorizontalContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
`;

const ReaderAvailability = ({ t: translate, location }) => {
  const [loadingConfigurations, setLoadingConfigurations] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [filterValue, setFilterValue] = useState(false);
  const [paginationContent, setPaginationContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { setModal, removeModal } = useModal();
  const [listAvailabilities] = useState(true);
  const readerName = getOr(false, 'name', location.state);
  const readerPath = getOr(false, 'path', location.state);
  const allResultsOption = { label: 'All', value: 'ALL' };

  const FormModal = styled.div`
    ${({ theme }) => css`
      padding: ${theme.spacings.giga};
    `};
  `;

  const getReaderAvailability = () => {
    setLoadingConfigurations(true);

    getReaderAvailabilityData(readerPath)
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(response => {
        setAvailabilities(response);
        setCountriesFilter(mapCountriesAvailable(response));
        calculatePaginationItens(response, setPaginationContent);
      })
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingConfigurations(false));
  };

  const toggleAvailabilityModal = (reader, countries, isAvailable) => {
    return (
      <FormModal id="response-modal">
        <ConfirmationModal
          action={() => {
            toggleAvailabilityBulk(reader, countries, isAvailable).then(
              responses => {
                if (!responses.find(response => !response.ok)) {
                  removeModal(toggleAvailabilityModal());
                  getReaderAvailability();
                }
                return responses;
              }
            );
          }}
          close={() => removeModal(toggleAvailabilityModal())}
          translate={translate}
          text={'readerAvailability.toggleAvailabilityModalText'}
        />
      </FormModal>
    );
  };

  const showToggleAvailabilityModal = (reader, countries, isAvailable) =>
    setModal({
      children: toggleAvailabilityModal(reader, countries, isAvailable),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const renderAvailabilitiesTable = () => {
    if (paginationContent && paginationContent[currentPage]) {
      return shouldRenderTable(
        paginationContent[currentPage],
        getReaderAvailability,
        translate,
        showToggleAvailabilityModal
      );
    }
    return false;
  };

  const shouldShowPagination = paginationContent.length > 1;

  const filterResults = filter => {
    // eslint-disable-next-line no-unused-expressions
    filter === allResultsOption.value
      ? calculatePaginationItens(availabilities, setPaginationContent)
      : calculatePaginationItens(
          filterByCountry(filter, availabilities),
          setPaginationContent
        );

    setFilterValue(filter);
    return setCurrentPage(1);
  };

  useEffect(() => {
    const controller = new AbortController();
    getReaderAvailability();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    filterResults(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (!readerName && !readerPath) {
      navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION);
    }
  }, []);

  return (
    <>
      {loadingConfigurations ? (
        <Loading />
      ) : (
        <>
          <MainContent>
            <SpaceBetweenHorizontalContainer>
              <FilterContainer>
                <Body noMargin>
                  {translate('cardReadersConfiguration.filter')}
                </Body>
                <StyledSelect
                  noMargin
                  label="Countries"
                  value={filterValue}
                  selected={filterValue}
                  options={[allResultsOption, ...countriesFilter]}
                  disabled={countriesFilter.length < 2 && !filterValue}
                  placeholder={translate('readerAvailability.country')}
                  onChange={event => filterResults(event.target.value)}
                  style={{ minWidth: '161px' }}
                />
              </FilterContainer>
              <BlackButton
                countries={countriesFilter.map(country => country.value)}
                loadingLabel="Loading..."
                onClick={() =>
                  showToggleAvailabilityModal(
                    readerPath,
                    countriesFilter.map(country => country.value),
                    true
                  )
                }
                size="kilo"
                variant="primary"
              >
                Set all offline
              </BlackButton>
              <BlackButton
                countries={countriesFilter.map(country => country.value)}
                loadingLabel="Loading..."
                onClick={() =>
                  showToggleAvailabilityModal(
                    readerPath,
                    countriesFilter.map(country => country.value),
                    false
                  )
                }
                size="kilo"
              >
                Set all online
              </BlackButton>
            </SpaceBetweenHorizontalContainer>

            <Container>
              <ReaderImageCard location={location} />

              {listAvailabilities && (
                <ContentContainer>
                  {loadingError ? (
                    <ErrorSectionContainer>
                      <DefaultSection
                        sectionImage={<ErrorIcon />}
                        sectionTitle={translate('genericError.title')}
                        sectionText={translate('genericError.text')}
                        titleSize="one"
                      />
                    </ErrorSectionContainer>
                  ) : (
                    <TableContainer>
                      {renderAvailabilitiesTable()}
                    </TableContainer>
                  )}
                </ContentContainer>
              )}
            </Container>
          </MainContent>

          <NavigationContainer>
            {listAvailabilities && shouldShowPagination && (
              <StyledPagination
                data-testid="pagination"
                label="Countries"
                previousLabel="Previous page"
                nextLabel="Next page"
                onChange={page => setCurrentPage(page)}
                currentPage={currentPage}
                totalPages={paginationContent.length - 1}
              />
            )}
          </NavigationContainer>
        </>
      )}
    </>
  );
};

ReaderAvailability.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default ReaderAvailability;
