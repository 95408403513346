import React from 'react';
import { Toggle, Table } from '@sumup/circuit-ui';

// import { formatDateTimeToShow } from 'services/DateTimeFormatService';

export const getReaderAvailabilityData = reader =>
  fetch(`/reader_availability_api/${reader}`);

// iterate over countries and call fetch for each one
// fetch(`/reader_availability_api/toggle/${reader}/${country}/${isAvailable}`, { method: 'POST' });
// return a Promise when all is done
export const toggleAvailabilityBulk = (reader, countries, isAvailable) => {
  return Promise.all(
    countries.map(country =>
      fetch(
        `/reader_availability_api/toggle/${reader}/${country}/${isAvailable}`,
        {
          method: 'POST'
        }
      )
    )
  );
};

const getFlagEmoji = countryCode => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const renderAvailabilityButton = (
  reader,
  country,
  isAvailable,
  toggleAvailabilityModal
) => {
  if (isAvailable === undefined || isAvailable === null) {
    return '';
  }
  const label = isAvailable ? 'Available' : 'Not Available';
  const countries = [country];
  return (
    <Toggle
      label={label}
      checkedLabel="Available"
      uncheckedLabel="Not Available"
      noMargin={true}
      checked={isAvailable}
      key={`${reader}:${country}`}
      onChange={() => {
        toggleAvailabilityModal(reader, countries, isAvailable);
      }}
    ></Toggle>
  );
};

export const mapAvailabilities = (row, toggleAvailabilityModal) => [
  {
    children: renderAvailabilityButton(
      row.readerType,
      row.country,
      row.status === 'AVAILABLE',
      toggleAvailabilityModal
    )
  },
  { children: `${getFlagEmoji(row.country)} ${row.country}` },
  { children: row.updatedAt }
];

export const mapCountriesAvailable = data => {
  const countryCodes = [];
  const availableCountries = data
    .filter(item => {
      const isUnique = !countryCodes.includes(item.country);
      if (isUnique) {
        countryCodes.push(item.country);
      }
      return isUnique && item;
    })
    .map(item => ({
      label: item.country,
      value: item.country
    }))
    .sort((prevItem, nextItem) => prevItem.label.localeCompare(nextItem.label));
  return availableCountries;
};

export const shouldRenderTable = (
  data,
  callback,
  translate,
  toggleAvailabilityModal
) => {
  return data ? (
    <Table
      noShadow
      headers={[
        {
          children: translate('readerAvailability.availability')
        },
        {
          children: translate('readerAvailability.country')
        },
        {
          children: translate('readerAvailability.lastUpdate')
        }
      ]}
      rows={data.map(row => mapAvailabilities(row, toggleAvailabilityModal))}
    />
  ) : (
    callback()
  );
};

export const filterByCountry = (countryCode, entries) =>
  entries.filter(item => item.country === countryCode);
